import React from 'react';
import {
  useTheme,
  ThemeProvider
} from '@material-ui/core'
import './App.css';
import { BrowserRouter } from 'react-router-dom'
// Routes
import Routes from './Routes';


function App() {
  const theme = useTheme();
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
