import { combineReducers } from 'redux'
import appointment from './appointment'
import generalParameters from './generalParameters'
import generalError from './generalError'
import documentPageOptions from './documentPageOptions'
import reposiciones from './reposiciones'

const rootReducer = combineReducers({
    appointment,
    generalParameters,
    generalError,
    documentPageOptions,
    reposiciones
})

export default rootReducer