import queryString from 'query-string';
import {
    SET_PAGE_OPTIONS,
    SET_SHOW_CONTACT_FROM,
    SET_HIDE_CONTACT_FROM,
    SET_SHOW_CONTACT_ADDED,
    SET_BACK_TO_HOME,
    SET_SHOW_ACCEPT_REPO,
    SET_SHOW_UPLOADING_DOCUMENT,
    SET_SHOW_DOCUMENT_UPLOADED,
    SET_SHOW_NOT_INTERESTED_REASONS,
} from 'actions/documentPageOptionsActions'
import {
    SET_APPOINTMENT,
    SET_APPOINTMENT_TRUCKER_PREFERENTIAL_RESPONSE
} from 'actions/appointmentActions'

const documentPageOptions = (state = {}, action) => {
    let params = queryString.parse(window.location.search);
    switch (action.type) {
        case SET_BACK_TO_HOME:
        case SET_PAGE_OPTIONS:
            return {
                ...state,
                uploadingDocument: null,
                successMessage: null,
                showBackButton: false,
                showMissingDocsButtons: false,
                uploadedDoc: null,
                showDocumentsFooter: true,
                showDocumentsHeader: true,
                showDocumentsSection: true,
                showDocumentsContact: params.contact && params.contact === 'true' ? false : (!state.truckerInterested ? false : true),//state.showDocumentsContact,
                showRepositionSection: false,//params.contact && params.contact === 'true' ? false : true,//Temporalmente quitare la seccion de reposiciones
                showDocumentsRequests: true,//params.contact && params.contact === 'true' ? true : state.showDocumentsRequests,
                showPreferentialQueueInterestSection: false,//params.contact && params.contact === 'true' ? false : state.showPreferentialQueueInterestSection,
                contactName: params.name ? params.name : null,
                contact: params.contact && params.contact === 'true' ? true : false,
                ...action.options,
            };
        case SET_APPOINTMENT:
            let interested = action.appointment.truckerInterested;
            return {
                ...state,
                truckerInterested: interested,
                showPreferentialQueueInterestSection: false,//!interested,
                showDocumentsContact: (interested === 1) ? true : false,
                showDocumentsSection: (interested === 1) ? true : false,
                showDocumentsRequests: true//(interested === 1) ? true : false,
            };
        case SET_SHOW_CONTACT_FROM:
            return {
                ...state,
                showDocumentsHeader: false,
                showDocumentsFooter: false,
                showRepositionSection: false,
                showDocumentsSection: false
            };
        case SET_HIDE_CONTACT_FROM:
            return {
                ...state,
                showDocumentsHeader: true,
                showDocumentsFooter: true,
                showRepositionSection: false,//true,//Temporalmente quitare la seccion de reposiciones
                showDocumentsSection: true
            };
        case SET_SHOW_CONTACT_ADDED:
            return {
                ...state,
                showDocumentsContact: false,
                successMessage: 'Gracias por la información de contacto para conseguir los documentos.',
                showBackButton: true,
                showMissingDocsButtons: false,
            };
        case SET_SHOW_ACCEPT_REPO:
            return {
                ...state,
                showDocumentsHeader: false,
                showDocumentsFooter: false,
                showRepositionSection: false,
                showDocumentsSection: false,
                showDocumentsContact: false,
                successMessage: 'Gracias. Lo estaremos contactando con opciones para reposición.',
                showBackButton: true,
                showMissingDocsButtons: false,
            };
        case SET_SHOW_UPLOADING_DOCUMENT:
            return {
                ...state,
                uploadingDocument: action.documentName,
                uploadedDoc: action.documentName,
                showDocumentsFooter: false,
                showDocumentsHeader: false,
                showDocumentsContact: false,
                showRepositionSection: false,
                showMissingDocsButtons: false,
                showDocumentsSection: false,
                showBackButton: false,
                successMessage: null,
            };
        case SET_SHOW_DOCUMENT_UPLOADED:
            return {
                ...state,
                uploadingDocument: null,
                successMessage: action.successMessage,
                uploadedDoc: {
                    ...action.uploadedDoc,
                    uploadedDocLinkText: action.uploadedDocLinkText
                },
                showMissingDocsButtons: action.missingDocs ? true : false,
                showDocumentsFooter: false,
                showDocumentsHeader: false,
                showDocumentsContact: false,
                showRepositionSection: false,
                showDocumentsSection: false,
                showBackButton: true
            };
        case SET_APPOINTMENT_TRUCKER_PREFERENTIAL_RESPONSE:
            return {
                ...state,
                showDocumentsSection: action.data.SMSTruckerNotInterested === 1 ? true : false,
                showDocumentsContact: action.data.SMSTruckerNotInterested === 1 ? true : false,
                showDocumentsRequests: true//action.data.SMSTruckerNotInterested === 1 ? true : false,
            }
        case SET_SHOW_NOT_INTERESTED_REASONS:
            return {
                ...state,
                showNotInterestedReasons: action.show,
                showMainOptions: !action.show
            }
        default:
            return state
    }
}

export default documentPageOptions;