import React, { Suspense, lazy } from 'react';
import { CircularProgress } from '@material-ui/core'
import GeneralError from 'components/generalError'
import {
  Switch, Route,
} from 'react-router-dom';
import { Provider } from 'react-redux'
import 'i18n'
import store from 'store'
const Home = lazy(() => import('pages/Home'));
const VerifySMS = lazy(() => import('pages/VerifySMS'));
const TruckerUploadAppointmentDocsV2 = lazy(() => import('pages/TruckerUploadAppointmentDocsV2/TruckerUploadAppointmentDocsV2'));
const TruckerDocumentsUpload = lazy(() => import('pages/TruckerDocumentsUpload'))
const Confirmation = lazy(() => import('pages/Confirmation'))
const ReposicionesDisponibles = lazy(() => import('pages/Reposiciones/ReposicionesDisponibles'))
const Oferta = lazy(() => import('pages/Reposiciones/Oferta'))
const QrCita = lazy(() => import('pages/QR'))
const LlegadaPatio = lazy(() => import('pages/LlegadaPatio'))
const ConfirmacionNotificacionCola = lazy(() => import('pages/ConfirmacionNotificacionCola'))
const ColaDocs = lazy(() => import('pages/ColaDocs'))




export default function Routes() {
  return (
    <Provider store={store}>
      <Suspense
        fallback={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100vh',
              width: '100vw',
              flexDirection: 'column'
            }}
          >
            <CircularProgress
              color="primary"
              style={{ fontSize: 40 }}
            />
            <div>Cargando...</div>
          </div>
        }
      >
        <GeneralError />
        <Switch>
          <Route exact from="/" component={Home} />
          <Route component={VerifySMS} exact path="/verify/:truckerId/sms/:code" />
          <Route component={TruckerUploadAppointmentDocsV2} exact path="/documentos/:truckerId/sms/:code" />
          <Route component={TruckerUploadAppointmentDocsV2} exact path="/documentos/:truckerId/smsv2/:code" />
          <Route component={TruckerDocumentsUpload} exact path="/documentos/:operationToken" />
          <Route component={ReposicionesDisponibles} exact path="/reposiciones/:operationToken" />
          <Route component={Oferta} exact path="/oferta/:operationToken" />
          <Route component={Confirmation} exact path="/confirmacion/:operationToken" />
          <Route component={QrCita} exact path="/qr/:operationToken" />
          <Route component={LlegadaPatio} exact path="/llegada/:operationToken" />
          <Route component={ConfirmacionNotificacionCola} exact path="/cnc/:operationToken" />
          <Route component={ColaDocs} exact path="/coladocs/:operationToken" />
        </Switch>
      </Suspense>
    </Provider>
  );
}
