import {
    SET_FETCHING_REPOSICIONES,
    SET_REPOSICIONES
} from 'actions/reposicionesActions'

function reposiciones(state = {}, action) {
    switch (action.type) {
        case SET_FETCHING_REPOSICIONES:
            return state;
        case SET_REPOSICIONES:
            return action.repos;
        default:
            return state;
    }
}

export default reposiciones;