export const SET_FETCHING_REPOSICIONES = 'SET_FETCHING_REPOSICIONES'
export const SET_REPOSICIONES = 'SET_REPOSICIONES'


export function setFetchingReposiciones() {
    return {
        type: SET_FETCHING_REPOSICIONES,
    }
}

export function setReposiciones(repos) {
    return {
        type: SET_REPOSICIONES,
        repos
    }
}