export const SET_PAGE_OPTIONS = 'SET_PAGE_OPTIONS';
export const SET_SHOW_CONTACT_FROM = 'SET_SHOW_CONTACT_FROM';
export const SET_HIDE_CONTACT_FROM = 'SET_HIDE_CONTACT_FROM';
export const SET_SHOW_CONTACT_ADDED = 'SET_SHOW_CONTACT_ADDED';
export const SET_BACK_TO_HOME = 'SET_BACK_TO_HOME';
export const SET_SHOW_ACCEPT_REPO = 'SET_SHOW_ACCEPT_REPO';
export const SET_SHOW_UPLOADING_DOCUMENT = 'SET_SHOW_UPLOADING_DOCUMENT';
export const SET_SHOW_DOCUMENT_UPLOADED = 'SET_SHOW_DOCUMENT_UPLOADED';
export const SET_SHOW_PREFERENTIAL_ACCEPTED = 'SET_SHOW_PREFERENTIAL_ACCEPTED';
export const SET_SHOW_NOT_INTERESTED_REASONS = 'SET_SHOW_NOT_INTERESTED_REASONS';

export function setPageOptions(options) {
    return {
        type: SET_PAGE_OPTIONS,
        options
    }
}

export function showContactForm() {
    return {
        type: SET_SHOW_CONTACT_FROM
    }
}
export function hideContactForm() {
    return {
        type: SET_HIDE_CONTACT_FROM
    }
}

export function showContactAddedSuccessfully() {
    return {
        type: SET_SHOW_CONTACT_ADDED
    }
}

export function backToHome() {
    return {
        type: SET_BACK_TO_HOME
    }
}

export function showRepoAccepted() {
    return {
        type: SET_SHOW_ACCEPT_REPO
    }
}

export function showUploadingDocument(documentName) {
    return {
        type: SET_SHOW_UPLOADING_DOCUMENT,
        documentName
    }
}
export function showDocumentUploaded(documentName, successMessage, uploadedDoc, uploadedDocLinkText, missingDocs) {
    return {
        type: SET_SHOW_DOCUMENT_UPLOADED,
        documentName,
        successMessage,
        uploadedDoc,
        uploadedDocLinkText,
        missingDocs
    }
}

export function showPreferentialAccepted() {
    return {
        type: SET_SHOW_PREFERENTIAL_ACCEPTED
    }
}
export function showNotInterestedReasons(show) {
    return {
        type: SET_SHOW_NOT_INTERESTED_REASONS,
        show
    }
}


