export default function generalParameters(state = {}, action) {
    switch (action.type) {
        case 'SET_APP_VERSION_DATA':
            return {
                ...state,
                comentarios: action.comentarios,
                appData: action.data
            }
        default:
            return state;
    }
}