export default {
    en: {
        translation: {
        },
    },
    es: {
        translation: {
            

        }
    }
}